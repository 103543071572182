/* Ugly Hack: click event won't fire on mobile, so I'm inlining event calls
for the menu */

function closeMenu() {
    $(".mobile-menu-close-button").click(function(){
        $(".mobile-menu-wrapper").slideUp();
    });
}
function openMenu() {
    $(".mobile-menu-wrapper").slideDown();
}