$(document).ready(function(){
    function adjustNavOpacity() {
        var scrollPosition = $(window).scrollTop();
        var opacity = .78 + (scrollPosition / 2500.0);
        var alpha = Math.min(opacity, .95);
        $("nav").css("background-color", "rgba(0, 0, 0, " + alpha + ")");
    
    }

    adjustNavOpacity();

    $(window).scroll(function(){
        adjustNavOpacity();
    })
})